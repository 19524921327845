import request from "@/utils/request";


export async function getSettings() {
  return await request({
    url: '/settings/get',
    method: "get",
  });
}


export async function updateSettings(data) {
  return await request({
    url: '/settings/update',
    method: "post",
    data
  });
}
