<template>
  <div id="settings">
    <el-form 
      ref="form" 
      :model="detail" 
      :rules="rules"
      inline
      label-width="300px"
      :label-position="isMobile ? 'top' : 'left'"
      @submit.native.prevent="handleSubmit"
      v-loading="isLoading"
      :disabled="!fullAccess"
    >
      <el-divider>{{ $t("COIN_GAIN") }}</el-divider>

      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
        <el-col>
          <el-form-item :label="$t('EACH_COMMENT')" prop="commentCoins">
            <el-input v-model.number="detail.commentCoins">
              <template slot="append">{{ $t('COIN_S_') }}</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item :label="$t('EACH_NEWS_ARTICLE')" prop="postArticleCoins">
            <el-input v-model.number="detail.postArticleCoins">
              <template slot="append">{{ $t('COIN_S_') }}</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
        <el-col>
          <el-form-item :label="$t('EACH_ARTICLE_SHARED')" prop="shareArticleCoins">
            <el-input v-model.number="detail.shareArticleCoins">
              <template slot="append">{{ $t('COIN_S_') }}</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-divider>{{ $t("ARTICLE") }}</el-divider>

      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
        <el-col :span="isMobile ? 24 : 12">
          <el-form-item :label="$t('ARTICLE_CREATION_COOLING_PERIOD')" prop="articleCoolingTimer">
            <el-input v-model.number="detail.articleCoolingTimer" class="x-appended">
              <template slot="append">{{ $t("MINUTE_S") }}</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-divider>{{ $t("DAILY_ATTENDANCE") }}</el-divider>

      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
        <el-col>
          <el-form-item :label="$t('DAILY_ATTENDANCE')" prop="dailyAttendanceCoins">
            <el-input v-model.number="detail.dailyAttendanceCoins">
              <template slot="append">{{ $t('COIN_S_') }}</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
        <el-col>
          <el-form-item :label="$t('NO_OF_READ')" prop="readArticleRequirement">
            <el-input v-model.number="detail.readArticleRequirement" />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item :label="$t('STAY_MINUTE')" prop="stayAliveRequirement">
            <el-input v-model.number="detail.stayAliveRequirement" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-divider>{{ $t("MEMBER_MULTIPLIER") }}</el-divider>

      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
        <el-col>
          <el-form-item :label="$t('LEVEL_X_MULTIPLIER', { num : 1 })" prop="levelOneMultiplier">
            <el-input v-model="detail.levelOneMultiplier" class="x-appended">
              <template slot="append">X</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item :label="$t('LEVEL_X_MULTIPLIER', { num : 2 })" prop="levelTwoMultiplier">
            <el-input v-model="detail.levelTwoMultiplier" class="x-appended">
              <template slot="append">X</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
        <el-col>
          <el-form-item :label="$t('LEVEL_X_MULTIPLIER', { num : 3 })" prop="levelThreeMultiplier">
            <el-input v-model="detail.levelThreeMultiplier" class="x-appended">
              <template slot="append">X</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item :label="$t('LEVEL_X_MULTIPLIER', { num : 4 })" prop="levelFourMultiplier">
            <el-input v-model="detail.levelFourMultiplier" class="x-appended">
              <template slot="append">X</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-divider>{{ $t("MEMBER_UPGRADE") }}</el-divider>

      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
        <el-col>
          <el-form-item :label="$t('LEVEL_X_UPGRADE', { num : 2 })" prop="levelTwoUpgrade">
            <el-input v-model.number="detail.levelTwoUpgrade">
              <template slot="append">{{ $t('COIN_S_') }}</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item :label="$t('LEVEL_X_UPGRADE', { num : 3 })" prop="levelThreeUpgrade">
            <el-input v-model.number="detail.levelThreeUpgrade">
              <template slot="append">{{ $t('COIN_S_') }}</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">
        <el-col>
          <el-form-item :label="$t('LEVEL_X_UPGRADE', { num : 4 })" prop="levelFourUpgrade">
            <el-input v-model.number="detail.levelFourUpgrade">
              <template slot="append">{{ $t('COIN_S_') }}</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col>
          <!-- Purposely leave blank -->
        </el-col>
      </el-row>

      <page-footer
        :isLoading="isLoading"
        :handleSubmit="fullAccess ? () => {} : null"
        :submitBtnText="$t('SUBMIT')"
      />

    </el-form>
  </div>
</template>

<script>
import i18n from '@/locales'
import PageFooter from "@/components/page-footer.vue"
import { generalMixin } from '@/utils/general-mixin.js';
import { getSettings, updateSettings } from "@/api/settings"
import { validateNum } from "@/utils/helpers"

export default {
  name: "Settings",
  components: {
    PageFooter
  },
  mixins: [generalMixin],
  data() {
    return {
      isLoading: false,
      detail: {
        levelOneMultiplier: 0,
        levelTwoMultiplier: 0,
        levelThreeMultiplier: 0,
        levelFourMultiplier: 0,
        levelTwoUpgrade: 0,
        levelThreeUpgrade: 0,
        levelFourUpgrade: 0,
        readArticleRequirement: 0,
        stayAliveRequirement: 0,
        commentCoins: 0,
        dailyAttendanceCoins: 0,
        postArticleCoins: 0
      },
      rules: {
        levelOneMultiplier: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { validator: this.validateMultiplier, trigger: 'blur' }
        ],
        levelTwoMultiplier: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { validator: this.validateMultiplier, trigger: 'blur' }
        ],
        levelThreeMultiplier: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { validator: this.validateMultiplier, trigger: 'blur' }
        ],
        levelFourMultiplier: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { validator: this.validateMultiplier, trigger: 'blur' }
        ],
        levelTwoUpgrade: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { type: 'number', message: i18n.t("ONLY_NUMBER"), trigger: "blur"}, // additional checking
          { validator: this.validateUpgrade, trigger: 'blur' }
        ],
        levelThreeUpgrade: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { type: 'number', message: i18n.t("ONLY_NUMBER"), trigger: "blur"}, // additional checking
          { validator: this.validateUpgrade, trigger: 'blur' }
        ],
        levelFourUpgrade: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { type: 'number', message: i18n.t("ONLY_NUMBER"), trigger: "blur"}, // additional checking
          { validator: this.validateUpgrade, trigger: 'blur' }
        ],
        readArticleRequirement: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { type: 'number', message: i18n.t("ONLY_NUMBER"), trigger: "blur"} // additional checking
        ],
        stayAliveRequirement: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { type: 'number', message: i18n.t("ONLY_NUMBER"), trigger: "blur"} // additional checking
        ],
        commentCoins: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { type: 'number', message: i18n.t("ONLY_NUMBER"), trigger: "blur"} // additional checking
        ],
        dailyAttendanceCoins: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { type: 'number', message: i18n.t("ONLY_NUMBER"), trigger: "blur"} // additional checking
        ],
        postArticleCoins: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { type: 'number', message: i18n.t("ONLY_NUMBER"), trigger: "blur"} // additional checking
        ],
        articleCoolingTimer: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { type: 'number', message: i18n.t("ONLY_NUMBER"), trigger: "blur"} // additional checking
        ],
        shareArticleCoins: [
          { required: true, message: i18n.t("FIELD_REQUIRED"), trigger: "blur" },
          { type: 'number', message: i18n.t("ONLY_NUMBER"), trigger: "blur"} // additional checking
        ],
      },
    }
  },
  methods: {
    validateMultiplier(_, value, callback) {
      if (validateNum(value) == false) {
        callback(this.$t("ONLY_NUMBER"))
      }
      callback();
    },
    validateUpgrade(_, __, callback) {
      const two = this.detail.levelTwoUpgrade
      const three = this.detail.levelThreeUpgrade
      const four = this.detail.levelFourUpgrade
      if (four < three || three < two || two > four) {
        callback(this.$t("UPGRADE_PREV_NOT_VALID"))
      } else if (four === three || three === two) {
        callback(this.$t("UPGRADE_CANNOT_SAME_AS_PREV"))
      } else {
        this.$refs["form"].clearValidate("levelTwoUpgrade")
        this.$refs["form"].clearValidate("levelThreeUpgrade")
        this.$refs["form"].clearValidate("levelFourUpgrade")
        callback()
      }

    },
    getDetail() {
      this.isLoading = true
      getSettings().then(res => {
        if (res.status != "ok") return
        this.detail = res.result
      })
      .finally(() => this.isLoading = false)
    },
    handleSubmit() {
      if (!this.fullAccess) return
      this.$refs["form"].validate((valid) => {
        if (!valid) return;

        this.detail.levelOneMultiplier = Number(this.detail.levelOneMultiplier)
        this.detail.levelTwoMultiplier = Number(this.detail.levelTwoMultiplier)
        this.detail.levelThreeMultiplier = Number(this.detail.levelThreeMultiplier)
        this.detail.levelFourMultiplier = Number(this.detail.levelFourMultiplier)

        updateSettings(this.detail).then(res => {
          if (res.status == 'ok') {
            this.$notify({
              type: 'success',
              message: this.$t("UPDATE_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            })
          }
        })
      });
    }
  },
  mounted() {
    this.getDetail()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#settings {
  .el-form {
    background-color: $white;
    padding: 3rem;
    .el-form-item__label {
      border: 1px solid #F0F0F0;
      border-radius: 5px;
      background-color: #F7F8FB;
      margin-right: 1rem;
      max-width: 13rem;
    }
    .x-appended {
      .el-input-group__append {
        padding: 5px;
      }
    }
    .el-form-item__error {
      white-space: nowrap
    }
  }
  .el-divider {
    margin-bottom: 4rem;
    .el-divider__text {
      font-size: 1rem;
      font-weight: bold;
      color: $primary;
    }
  }
  .el-input {
    max-width: 10rem
  }
}

.mobile #settings {
  .el-form {
    padding: 1.5rem;
    .el-form-item {
      width: 100%;
      .el-form-item__label {
        width: 100% !important;
        padding-bottom: 0;
      }
      .el-input {
        width: 100%;
      }
    }
  }
  .el-divider {
    margin-bottom: 2rem;
  }
  #page-footer {
    .el-row {
      margin-left: 0;
    }
  }
}
</style>